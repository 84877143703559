@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Poppins', sans-serif;
}

.bg-image {
    background-image: url(./assets/images/OrangeBackground.jpg);
    background-position: center;
    background-size: cover;
}

h1 {
    @apply text-xl font-medium capitalize;
}

.link {
    @apply p-2.5 flex rounded-md gap-6 items-center md:cursor-pointer duration-300 font-medium;
}

.active {
    @apply bg-light text-primary
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #9098B1;
    border-radius: 5px;
}

/* Arrow Animation */
.arrow-animation {
    transform-origin: 0% 50%;
    animation: arrow 2s linear infinite;
}

@keyframes arrow {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(10px, 0);
    }
}

/* ============================================= */

.checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox+label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
}

.checkbox+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    border: 1px solid #aaa;
    background: #fff;
    border-radius: 3px;
}

.checkbox:checked+label:before {
    content: '✓';
    background: #458EEF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: #458EEF;
    border-radius: 3px;
}